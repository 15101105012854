import {AnswerOrderEnum, EsercitazioneEnum, ModalitaEsercitazioneEnum, QuizOrderEnum} from "./Constants";
import StatsManager from "./StatsManager";
import {v4 as uuidv4} from 'uuid';
import _ from 'lodash';
import {knuthShuffle as shuffle} from 'knuth-shuffle';
import {Errors} from "./Messages";
import WrappedWorker from "./WrappedWorker";
import CustomReportisticaApi from "../CustomConcorsandoClient/api/CustomReportisticaApi";
import ReportisticaApi from "../ConcorsandoApi/api/ReportisticaApi";
import {promisify, rispostaRiepilogoToRispostaVO, rispostaVOToRispostaRiepilogo} from "../utils";
import UtenteApi from "@/lib/ConcorsandoApi/api/UtenteApi";

/**
 * @typedef EsercitazioneMeta
 * @property {String} id_concorso
 * @property {String} uuid_esercitazione
 * @property {String} tipo_esercitazione - valori ammessi in `EsercitazioneEnum`
 * @property {String} [modalita_esercitazione] - se tipo_esercitazione è EsercitazioneEnum.ESERCITAZIONE, valori ammessi in `ModalitaEsercitazioneEnum`
 * @property {Number} tempo_tot
 */

/**
 * @typedef QuizAnswer
 * @property {String} testo
 * @property {String} id
 * @property {boolean} esatta
 */

/**
 * @typedef Quiz
 * @property {String} id
 * @property {String} domanda
 * @property {boolean} has_image
 * @property {String | null} url_img
 * @property {Array<QuizAnswer>} risposte
 * @property {String} [selectedAnswer] - l'id della risposta selezionata durante la stessa sessione
 * @property {Number} num_risposte - il numero totale di risposte
 * @property {String} [risposta_gettonata] - l'id della risposta scelta dall'utente
 * @property {String | null} url_wiki - il link a wikipedia
 * @property {String | null} commento - l'id della spiegazione
 * @property {String | null} id_ente
 * @property {String | null} ente
 * @property {String | null} id_concorso
 * @property {String | null} concorso
 * @property {String | null} anno
 * @property {String | null} id_materia
 * @property {String | null} materia
 * @property {String | null} indice_uff
 */

/**
 * @typedef RispostaRiepilogo
 * @property {string} id_domanda
 * @property {string} id_simulazione
 * @property {string} id_materia
 * @property {number} [risposta]
 * @property {number} [isCorrectAnswer]
 */

const privateState = {
  domande: [],
  /**
   * @type {Object.<String, Quiz>}
   */
  quiz: {},
  // Gli id dei quiz a cui è stata data una risposta durante la sessione, da passare a statsManager per limitare a questi l'invio degli update
  sessionQuizzes: [],
};

export default class EsercitazioneManager {
  constructor(statsManagerInstance = StatsManager.instance, workerInstance = WrappedWorker.instance) {
    this.id_concorso = undefined;
    this.id_materia = undefined;
    this.id_simulazione = undefined;
    this.uuid_esercitazione = undefined;
    this.tipo_esercitazione = undefined;
    this.n_quiz = undefined;
    this.tempo_tot = undefined;
    this.modalita_esercitazione = undefined;
    this.config_punteggio = undefined;
    this.dettaglioRisposteGettonate = [];

    this.statsManagerInstance = statsManagerInstance;
    this.workerInstance = workerInstance;


    /**
     * @type {Array<RispostaRiepilogo>}
     */
    this.riepilogo = [];

    this.customReportisticaApi = new CustomReportisticaApi();
    this.reportisticaApi = new ReportisticaApi();
    this.utenteApi = new UtenteApi();
  }

  /**
   * Ritorna i metadati dell'esercitazione
   * @returns {Promise<EsercitazioneMeta>}
   */
  async getEsercitazioneMeta() {
    return _.cloneDeep({
      id_concorso: this.id_concorso,
      id_simulazione: this.id_simulazione,
      riepilogo: this.riepilogo,
      uuid_esercitazione: this.uuid_esercitazione,
      tipo_esercitazione: this.tipo_esercitazione,
      modalita_esercitazione: this.modalita_esercitazione,
      tempo_tot: this.tempo_tot,
      n_quiz: this.n_quiz,
      config_punteggio: this.config_punteggio,
    })
  }

  checkInitialized(preventThrow = false) {
    if (this.id_concorso == null || this.uuid_esercitazione == null || this.tipo_esercitazione == null) {
      if (preventThrow) {
        return false;
      } else {
        throw {code: Errors.ESERCITAZIONE_NON_INIZIALIZZATA}
      }
    }
    else {
      return true;
    }
  }

  /**
   * @typedef SetupEsercitazioneOptions
   * @property {String} sortDomande - i valori ammessi sono in QuizOrderEnum
   * @property {String} sortRisposte - i valori ammessi sono in AnswerOrderEnum
   * @property {number[]} livelli_conoscenza - i livelli conoscenza da ritornare (vd. `LivelliConoscenzaEnum` per i livelli conoscenza ammessi)
   * @property {String | void} modalita_esercitazione - la modalità dell'esercitazione, a sincronizzazione in remoto viene effettuata solo incaso "ESEGUITA" (vd. `ModalitaEsercitazioneEnum` per i valori ammessi)
   */

  /**
   * Effettua il setup dell'esercitazione. Prende automaticamente le domande impostate nel filtro (in base all'ultima
   * chiamata a StatsManager), esclusi i livelli conoscenza che vanno passati a mano.
   * @param {SetupEsercitazioneOptions} options
   * @returns {string}
   */
  async setupEsercitazione(options = {}) {
    let {sortDomande = QuizOrderEnum.ALFABETICO, sortRisposte = AnswerOrderEnum.CASUALE, livelli_conoscenza = [], modalita_esercitazione} = options;
    this.clear();

    this.id_concorso = this.statsManagerInstance.id_concorso;
    this.uuid_esercitazione = uuidv4();
    this.sortRisposte = sortRisposte;
    // Se non viene passata, la modalità esercitazione è eseguita
    this.modalita_esercitazione = modalita_esercitazione || ModalitaEsercitazioneEnum.ESEGUITA;

    const setupPromise = this.workerInstance.initEsercitazione(this.id_concorso);

    let excludeImages = false;
    // Impostiamo l'ordinamento di domande e risposte in base alla modalità
    if (modalita_esercitazione === ModalitaEsercitazioneEnum.LETTA) {
      sortDomande = QuizOrderEnum.UFFICIALE;
      this.sortRisposte = AnswerOrderEnum.UFFICIALE;
    }
    else if (modalita_esercitazione === ModalitaEsercitazioneEnum.ASCOLTATA) {
      sortDomande = QuizOrderEnum.UFFICIALE;
      excludeImages = true;
    }

    let domande;
    let searchResultsByLevel;
    let searchResult;

    searchResult = this.statsManagerInstance.searchResult;
    if (searchResult === undefined) {
      searchResult = await this.workerInstance.searchDomandeByIds(
        this.statsManagerInstance.id_concorso,
        Object.keys(this.statsManagerInstance.searchResultLevelsMap)
          .map(this.workerInstance.getWasmId)
      );
    }

    if (livelli_conoscenza.length > 0) {
      searchResultsByLevel = searchResult.filter(x =>
        livelli_conoscenza.includes(this.statsManagerInstance.searchResultLevelsMap[this.workerInstance.getStatsId(x.id)])
      );
    }
    else {
      searchResultsByLevel = [...searchResult]
    }

    switch(sortDomande) {
      case QuizOrderEnum.ALFABETICO:
        domande = _.sortBy(searchResultsByLevel, ['domanda']);
        break;
      case QuizOrderEnum.CASUALE:
        domande = shuffle(searchResultsByLevel);
        break;
      case QuizOrderEnum.UFFICIALE:
        domande = _.sortBy(searchResultsByLevel, ['id']);
        break;
    }

    this.tipo_esercitazione = EsercitazioneEnum.ESERCITAZIONE;

    if (excludeImages) {
      const workerDomande = await this.workerInstance.getDomande(this.id_concorso);
      const ids_domande = domande.map(x => x.id)
      const domande_con_immagini = workerDomande
        .filter(x => ids_domande.includes(x.id) && x.has_image);

      domande = _.differenceBy(domande, domande_con_immagini, x => x.id);
    }

    this.n_quiz = domande.length;
    privateState.domande = domande.map(x => this.workerInstance.getStatsId(x.id));

    await setupPromise;
    return this.uuid_esercitazione;
  }

  async getStatisticheRisposteGettonate(id_concorso) {
    try {
      this.dettaglioRisposteGettonate = await promisify((...args) => this.reportisticaApi.geDettaglioRisposteGettonate(...args), {idConcorso: id_concorso});
    } catch (e) {
      console.error("Errore in GET dettaglio_risposte_gettonate: ", e);
    }
  }

  /**
   * @typedef SetupAssegnaRisposteOptions
   * @property {string} id_concorso
   * @property {string[]} [id_materie]
   * @property {boolean} [assegnate] - se true, visualizza le risposte già assegnate
   */

  /**
   * @param {SetupAssegnaRisposteOptions} options
   * @returns {Promise<void>}
   */
  async setupAssegnaRisposte(options = {}) {
    const {id_concorso, assegnate = false} = options;

    if (!id_concorso) {
      throw {code: Errors.MISSING_REQUIRED_PARAM, fields: "id_concorso"};
    }

    this.clear();

    // Define function to check that a row actually match
    let materie = Array.isArray(options.id_materie) ? options.id_materie : [];
    function matchMateria(id_materia) {
      if (materie.length > 0) {
        return materie.includes(id_materia);
      }
      else {
        return true;
      }
    }

    this.id_concorso = id_concorso;
    const setupPromise = this.workerInstance.initEsercitazione(this.id_concorso);

    this.uuid_esercitazione = uuidv4();
    const dettaglioRisposteGettonatePromise = this.getStatisticheRisposteGettonate(this.id_concorso);
    this.sortRisposte = AnswerOrderEnum.UFFICIALE;

    let filterQuiz = x => x.has_risposta === 0;
    if (assegnate) {
      filterQuiz = x => x.has_risposta === 1 && x.risposta_gettonata;
    }
    let domande = (await this.statsManagerInstance.getAllStats(this.id_concorso))
      .filter(filterQuiz)
      .filter(x => matchMateria(x.id_materia));

    // Odiniamo le domande per indice_uff
    domande = _.sortBy(domande, ['indice_uff']);

    this.tipo_esercitazione = EsercitazioneEnum.ASSEGNA_RISPOSTE;

    this.n_quiz = domande.length;
    privateState.domande = domande.map(x => String(x.id_domanda));

    await setupPromise;
    await dettaglioRisposteGettonatePromise;
    return this.uuid_esercitazione;
  }

  /**
   * @typedef SetupRivediProvaOptions
   * @property {string} id_concorso
   * @property {string} [id_materia]
   * @property {"CORRETTE" | "NON_RISPOSTE" | "ERRATE"} [answer_status_filter]
   */

  /**
   * Fa il setup della modalità rivedi prova. Può essere chiamato solo dopo aver fatto setupRiepilogo, in particolare
   * solo dalla pagina di riepilogo (risultati-concorso.html). Prende come opzioni l'id del concorso e l'eventuale id
   * della materia da mostrare.
   * @param {SetupRivediProvaOptions} options
   * @returns {Promise<void>}
   */
  async setupRivediProva(options = {}) {
    const {id_concorso, id_materia, answer_status_filter} = options;

    if (!this.checkInitialized(true) || this.tipo_esercitazione !== EsercitazioneEnum.RIEPILOGO) {
      throw {code: Errors.TIPO_ESERCITAZIONE_ERRATO};
    }

    if (this.id_concorso !== id_concorso) {
      throw {code: Errors.ID_CONCORSO_NON_COINCIDE};
    }

    this.tipo_esercitazione = EsercitazioneEnum.RIVEDI_PROVA;
    const setupPromise = this.workerInstance.initEsercitazione(this.id_concorso);

    // Se ho selezionato una materia, prendo dal riepilogo solo le domande di quella materia
    const materia_filter = id_materia ? x => _.get(x, "id_materia") === id_materia : () => true;
    let answer_filter;
    switch (answer_status_filter) {
      case "CORRETTE":
        answer_filter = x => x.isCorrectAnswer !== undefined && x.isCorrectAnswer;
        break;
      case "ERRATE":
        answer_filter = x => x.isCorrectAnswer !== undefined && !x.isCorrectAnswer;
        break;
      case "NON_RISPOSTE":
        answer_filter = x => x.isCorrectAnswer === undefined;
        break;
      default:
        answer_filter = () => true;
    }
    if (id_materia || answer_status_filter) {
      privateState.domande = this.riepilogo
        .filter(materia_filter)
        .filter(answer_filter)
        .map(x => String(x.id_domanda));
      this.n_quiz = privateState.domande.length;
    }

    await setupPromise;
    return this.uuid_esercitazione;
  }

  /**
   * @typedef SetupRiepilogoOptions
   * @property {RispostaVO[]} [riepilogo] come ritornata da /ms_reportistica/risposte_simulazione
   * @property {string} id_concorso
   * @property {string} [id_simulazione]
   * @property {number} [punteggio_risposte_corrette] come ritornato da /ms_concorso/simulazione
   * @property {number} [punteggio_risposte_sbagliate] come ritornato da /ms_concorso/simulazione
   * @property {number} [punteggio_non_risposte] come ritornato da /ms_concorso/simulazione
   */

  /**
   * Fa il setup della modalità riepilogo. Viene chiamato automaticamente da concludiProva. Dalla pagina di simulazione
   * invece è necessario chiamarlo passando come parametri riepilogo (GET /ms_reportistica/risposte_simulazione), id_simulazione e i
   * valori dei punteggi come ritornati da GET /ms_concorso/simulazione.
   *
   * @param {SetupRiepilogoOptions} options
   * @returns {Promise<void>}
   */
  async setupRiepilogo(options = {}) {
    /* PLAN:
     *   (STEP 1) reinizializza se necessario
     *   (STEP 2) aggiungi i dati del setup che riguardano la prova, e.g. i punteggi
     *   (STEP 3) fai l'arricchimento del riepilogo se serve
     *   (STEP 4) prendi i dati sulle risposte esatte dal wasm
     */
    const {riepilogo = [], id_concorso, id_simulazione} = options;

    // STEP 1
    if (
      !this.checkInitialized(true) // se l'esercitazione non è inizializzata
      || this.id_concorso !== id_concorso // oppure il concorso è diverso
    ) { // Pulisco l'esercitazione e la reinizializzo
      this.clear();
      this.uuid_esercitazione = uuidv4();
      this.sortRisposte = AnswerOrderEnum.CASUALE;
      this.id_concorso = id_concorso;
    }

    // STEP 2
    const config_punteggio = _.pick(options, [
      "punteggio_risposte_corrette",
      "punteggio_risposte_sbagliate",
      "punteggio_non_risposte",
    ]);

    if (id_simulazione) {
      this.id_simulazione = id_simulazione;
    }

    if (!_.isEmpty(config_punteggio)) {
      this.config_punteggio = config_punteggio;
    }

    // STEP 3 e STEP 4
    if (riepilogo.length > 0) { // Se viene passato un riepilogo usiamo quello
      this.riepilogo = await this.statsManagerInstance.enrichRiepilogo(this.id_concorso, riepilogo.map(rispostaVOToRispostaRiepilogo));
    }

    this.tipo_esercitazione = EsercitazioneEnum.RIEPILOGO;
    this.modalita_esercitazione = undefined;

    this.n_quiz = this.riepilogo.length;
    privateState.domande = this.riepilogo.map(x => String(x.id_domanda));

    return this.uuid_esercitazione;
  }

  /**
   * @typedef ProvaPersonalizzataConfigPunteggio
   * @property {number} punteggio_risposte_corrette
   * @property {number} punteggio_risposte_sbagliate
   * @property {number} punteggio_non_risposte
   */

  /**
   * I nomi delle proprietà sono gli stessi ritornati da /ms_concorso/simulazione
   * @typedef SetupProvaPersonalizzataOptions
   * @property {string} id_concorso - id del concorso
   * @property {string} [id_simulazione] - id della simulazione (da passare se si tratta di una simulazione)
   * @property {string} durata - tempo totale, viene ritornato così come viene settato
   * @property {Array<{id_materia: string, numero: number}>} materie_simulazione - array di configurazioni {id_materia, numero}
   * @property {number} punteggio_risposte_corrette
   * @property {number} punteggio_risposte_sbagliate
   * @property {number} punteggio_non_risposte
   */

  /**
   * Un wrapper di _setupProvaPersonalizzata per fare il setup della prova personalizzata
   * @param {SetupProvaPersonalizzataOptions} options
   * @returns {Promise<*|undefined>}
   */
  async setupProvaPersonalizzata(options = {}) {
    return this._setupProvaPersonalizzata(EsercitazioneEnum.PROVA_PERSONALIZZATA, options);
  }

  /**
   * Un wrapper di _setupProvaPersonalizzata per fare il setup della simulazione
   * @param {SetupProvaPersonalizzataOptions} options
   * @returns {Promise<*|undefined>}
   */
  async setupSimulazione(options = {}) {
    return this._setupProvaPersonalizzata(EsercitazioneEnum.SIMULAZIONE, options);
  }

  /**
   * Metodo privato per fare il setup della prova personalizzata o della simulazione
   * @param {EsercitazioneEnum} tipo_esercitazione
   * @param {SetupProvaPersonalizzataOptions} options
   * @returns {Promise<*|undefined>}
   * @private
   */
  async _setupProvaPersonalizzata(tipo_esercitazione, options = {}) {
    /* PLAN:
     *   (STEP 1) prendi per ogni materia il numero di domande richieste
     *   (STEP 2) fai uno shuffle e salva gli id in privateState
     *   (STEP 3) aggiungi i dati del setup che riguardano la prova, e.g. il tempo
     *   (STEP 4) fai il resto del setup come per l'esercitazione
     */
    const {durata, materie_simulazione, id_concorso, id_simulazione} = options;
    const config_punteggio = _.pick(options, [
      "punteggio_risposte_corrette",
      "punteggio_risposte_sbagliate",
      "punteggio_non_risposte",
    ]);

    this.clear();

    this.id_concorso = id_concorso;
    this.uuid_esercitazione = uuidv4();
    this.tipo_esercitazione = tipo_esercitazione;
    if (tipo_esercitazione === EsercitazioneEnum.SIMULAZIONE) {
      this.id_simulazione = id_simulazione;
    }
    this.tempo_tot = durata;
    this.config_punteggio = config_punteggio;
    this.sortRisposte = AnswerOrderEnum.CASUALE;

    const setupPromise = this.workerInstance.initEsercitazione(this.id_concorso);

    let allStats = await this.statsManagerInstance.getAllStats(id_concorso);

    // STEP 1
    const statsByMateria = _.groupBy(allStats, 'id_materia');
    const searchResult = materie_simulazione
      .flatMap(({id_materia, numero}) => _.slice(
        shuffle(_.get(statsByMateria, id_materia, [])),
        0,
        numero
      ));

    let domande;

    // STEP 2
    domande = shuffle(searchResult);

    // STEP 4
    this.n_quiz = domande.length;
    privateState.domande = domande.map(x => String(x.id_domanda));

    await setupPromise;
    return this.uuid_esercitazione;
  }

  /**
   * Ritorna una domanda a partire dal suo indice.
   *
   * Se il quiz non ha una risposta esatta e ha una risposta_gettonata impostata dall'utente, la risposta corrispondente
   * è contrassegnata come esatta.
   *
   * La risposta_gettonata impostata dall'utente è ritornata esplicitamente nella proprietà risposta_gettonata del quiz.
   *
   * Se siamo in modalità ASSEGNA_RISPOSTE e ci sono delle statistiche di assegnazione della risposta, ritorniamo il valore
   * restituito dal servizio GET /ms_reportistica/dettaglio_risposte_gettonate nella proprietà dettaglio_risposta_gettonata del quiz.
   * @param {Number} n - indice della domanda, da 0 a this.n_quiz - 1
   * @returns {Promise<Quiz>}
   */
  async getQuiz(n) {
    this.checkInitialized();

    const ix = parseInt(n);
    if (ix < 0 || ix > this.n_quiz - 1) {
      throw {code: Errors.INDEX_OUT_OF_BOUND}
    }
    let id_domanda = privateState.domande[ix];
    if (privateState.quiz[id_domanda]) {
      return privateState.quiz[id_domanda];
    }

    const id_domanda_wasm = this.workerInstance.getWasmId(id_domanda);


    let res;
    let quizStats;
    let quizStatsOutput;
    try {
      /**
       * @type {StatsDomanda | undefined}
       */
      quizStats = await this.statsManagerInstance.getQuizStats(this.id_concorso, id_domanda);

      res = await this.workerInstance.getDomandeQuiz(this.id_concorso, [id_domanda_wasm]);
    } catch (e) {
      console.error(e);
      if (e && e.code) {
        throw e;
      }
      const quizStatsOutput = quizStats ? {...quizStats, id_domanda: id_domanda_wasm} : {id_domanda: id_domanda_wasm};
      throw {code: Errors.DOMANDA_NON_TROVATA, quizStats: quizStatsOutput }
    }

    // Nota: calcoliamo quizStatsOutput due volte perché il calcolo fatto nel catch precedente non può essere visto all'esterno,
    // ma non può nemmeno essere fatto prima.
    quizStatsOutput = quizStats ? {...quizStats, id_domanda: id_domanda_wasm} : {id_domanda: id_domanda_wasm};

    const quiz = _.find(res, {id: id_domanda_wasm});

    if (!quiz) {
      /* NOTA: se la domanda non è stata trovata, ci sono due possibilità:
       *  1 - c'è una incongruenza fra la banca dati e le statistiche
       *  2 - il modulo wasm non riesce a riesce a ritornare il quiz dal db, probabilmente perché fallisce il parsing
       */
      throw {code: Errors.DOMANDA_NON_TROVATA, quizStats: quizStatsOutput }
    }

    let risposte = [...quiz.risposte];

    // Se non c'è una risposta esatta e ho settato una risposta gettonata, la imposto come esatta
    if (risposte.filter(x => x.esatta).length === 0 && quizStats.risposta_gettonata) {
      let gettonata = _.find(risposte, {id: String(quizStats.risposta_gettonata)});
      if (gettonata) {
        gettonata.esatta = true;
      }
    }

    switch(this.sortRisposte) {
      case AnswerOrderEnum.ESATTE:
        risposte = risposte.filter(x => x.esatta)
        break;
      case AnswerOrderEnum.UFFICIALE:
        risposte = _.sortBy(risposte, ["id"]);
        break;
      case AnswerOrderEnum.CASUALE:
      default:
        risposte = shuffle(risposte)
        break;
    }

    const preferiti = _.get(quizStats, "preferiti") === 1;

    const quizSorted = {...quiz, risposte, preferiti};
    if (this.tipo_esercitazione === EsercitazioneEnum.RIVEDI_PROVA) {
      const risposta = _.get(_.find(this.riepilogo, {id_domanda}), "risposta");
      if (risposta) {
        quizSorted.selectedAnswer = String(risposta);
      }
    }
    else if (this.tipo_esercitazione === EsercitazioneEnum.ASSEGNA_RISPOSTE) {
      // Prendiamo le statistiche della domanda (se serve)
      const stats = _.find(this.dettaglioRisposteGettonate, x => String(_.get(x, "id")) === id_domanda_wasm || String(_.get(x, "id_domanda")) === id_domanda_wasm);
      if (stats) {
        quizSorted.dettaglio_risposta_gettonata = {...stats};
      }
    }

    if (quizStats.risposta_gettonata) {
      quizSorted.risposta_gettonata = String(quizStats.risposta_gettonata);
    }

    privateState.quiz[id_domanda] = quizSorted;

    return quizSorted;
  }

  /**
   * A partire dal quiz `n`, ritorna il prossimo quiz (ripartendo dall'inizio se serve) a cui non è stata data una risposta
   * @param {Number} n
   */
  getNextQuizIx(n) {
    this.checkInitialized();

    const ix = parseInt(n);
    if (ix < 0 || ix > this.n_quiz - 1) {
      throw {code: Errors.INDEX_OUT_OF_BOUND}
    }
    const allIds = [...new Array(this.n_quiz)].map((_x, k) => k);
    const nextIds = allIds.filter(x => x > ix);
    const startIds = allIds.filter(x => x < ix);
    const idsToTest = [...nextIds, ...startIds];
    for (let i of idsToTest) {
      const id_domanda = privateState.domande[i];
      const quiz = privateState.quiz[id_domanda];
      if (!quiz || quiz.selectedAnswer == undefined) {
        return i;
      }
    }
    return null
  }

  /**
   * @param {String} id_domanda
   * @param {String} id_risposta
   * @returns {Promise<void>}
   */
  async selectQuizAnswer(id_domanda, id_risposta) {
    this.checkInitialized();

    const id_domanda_stats = this.workerInstance.getStatsId(id_domanda);


    const quiz = privateState.quiz[id_domanda_stats];

    // Modifica la risposta in memoria
    if (quiz) {
      if (this.tipo_esercitazione === EsercitazioneEnum.ASSEGNA_RISPOSTE) {
        // Assegna la risposta come gettonata e aggiorna la proprietà esatta della risposta in memoria

        quiz.risposte.forEach(risposta => risposta.esatta = risposta.id === String(id_risposta));

        quiz.risposta_gettonata = id_risposta;

        privateState.sessionQuizzes.push(id_domanda_stats);
        await this.statsManagerInstance.setRispostaGettonata(this.id_concorso, id_domanda_stats, {risposta_gettonata: id_risposta, sessionQuizzes: privateState.sessionQuizzes})
      }
      else {
        // Seleziono la risposta come data

        quiz.selectedAnswer = id_risposta;
        const isCorrectAnswer = _.get(_.find(quiz.risposte, {id: id_risposta}), "esatta", false)

        // Aggiorna le statistiche
        if (
          this.tipo_esercitazione === EsercitazioneEnum.ESERCITAZIONE
          && this.modalita_esercitazione === ModalitaEsercitazioneEnum.ESEGUITA
        ) {
          privateState.sessionQuizzes.push(id_domanda_stats);
          this.statsManagerInstance.updateQuizStats(this.id_concorso, id_domanda_stats, {correct_answer: isCorrectAnswer, sessionQuizzes: privateState.sessionQuizzes})
            .catch(e => {
              console.error("Failed updateQuizStats", e);
            });
        }
      }
    }
    else {
      throw {
        code: Errors.IMPOSSIBILE_SELEZIONARE_RISPOSTA,
        message: "Il quiz indicato non è presente nella lista dei quiz scaricati. Verifica che l'id del quiz sia corretto."
      }
    }
  }

  async concludiProva() {
    /* STEP:
     *   - (!= rivedi_prova) salva i risultati della prova in un riepilogo
     *   - (simulazione) invia ai servizi richiesti il riepilogo della prova
     *   - fa il setup del riepilogo
     */
    if (
      this.tipo_esercitazione !== EsercitazioneEnum.PROVA_PERSONALIZZATA
      && this.tipo_esercitazione !== EsercitazioneEnum.SIMULAZIONE
      && this.tipo_esercitazione !== EsercitazioneEnum.RIVEDI_PROVA
    ) {
      throw {code: Errors.TIPO_ESERCITAZIONE_ERRATO}
    }

    if (this.tipo_esercitazione !== EsercitazioneEnum.RIVEDI_PROVA) {
      this.riepilogo = await Promise.all(privateState.domande
        .map(async (id_domanda) => {
          const quiz = privateState.quiz[id_domanda];
          const id_risposta = _.get(quiz, "selectedAnswer");
          const rispostaObj = id_risposta != null ? {risposta: parseInt(id_risposta)} : {};

          if (quiz && id_risposta != null) {
            rispostaObj.isCorrectAnswer = _.get(_.find(quiz.risposte, {id: id_risposta}), "esatta", false)
          }

          const idSimulazioneObj = this.id_simulazione != null ? {id_simulazione: String(this.id_simulazione)} : {};
          let id_materia;
          if (quiz) {
            id_materia = quiz.id_materia;
          } else {
            id_materia = (await this.statsManagerInstance.getQuizStats(this.id_concorso, id_domanda)).id_materia;
          }
          return {
            id_domanda,
            id_materia,
            ...idSimulazioneObj,
            ...rispostaObj
          }
        }));
    }

    if (this.tipo_esercitazione === EsercitazioneEnum.PROVA_PERSONALIZZATA) {
      await promisify((...args) => this.utenteApi.incrementaLivello(...args), {incremento: this.n_quiz});
    }

    if (this.tipo_esercitazione === EsercitazioneEnum.SIMULAZIONE) {
      await this.customReportisticaApi.risposteSimulazioneAsync(
        this.riepilogo.map(rispostaRiepilogoToRispostaVO),
        true
      );
      await promisify((...args) => this.reportisticaApi.esitiSimulazioni(...args), [this.buildEsitoSimulazione()])
    }

    return await this.setupRiepilogo({id_concorso: this.id_concorso});
  }

  /**
   * Costruisce l'oggetto EsitoSimulazioneVO da inviare al backend
   * @returns {EsitoSimulazioneVO}
   */
  buildEsitoSimulazione() {
    const domande_con_risposta = privateState.domande
      .map(id_domanda => privateState.quiz[id_domanda])
      .filter(x => !_.isEmpty(x) && x.selectedAnswer)

    const numero_risposte_corrette = domande_con_risposta
      .filter(quiz =>
        _.get(_.find(quiz.risposte, {id: quiz.selectedAnswer}), "esatta", false)
      )
      .length;
    const numero_risposte_errate = domande_con_risposta
      .filter(quiz =>
        !_.get(_.find(quiz.risposte, {id: quiz.selectedAnswer}), "esatta", false)
      )
      .length;
    return {
      id_simulazione: String(this.id_simulazione),
      data_simulazione: new Date(),
      numero_domande_previste: privateState.domande.length,
      numero_risposte_corrette,
      numero_risposte_errate,
      sorgente: "WEB",
      consegnata: true,
    }
  }

  /**
   * Aggiungi ai preferiti se add è true, rimuove se false.
   * @param id_domanda
   * @param add
   * @returns {Promise<void>}
   */
  async changeFavorites(id_domanda, add = true) {
    const id_domanda_stats = this.workerInstance.getStatsId(id_domanda);
    _.set(privateState.quiz[id_domanda_stats], "preferiti", add);
    try {
      return await this.statsManagerInstance.updateQuizStats(this.id_concorso, id_domanda_stats, {preferiti: add});
    } catch (e) {
      _.set(privateState.quiz[id_domanda_stats], "preferiti", !add);
      throw e;
    }
  }

  clear() {
    privateState.quiz = {};
    privateState.domande = [];
    privateState.sessionQuizzes = [];
    this.riepilogo = [];
    this.id_concorso = undefined;
    this.id_materia = undefined;
    this.id_simulazione = undefined;
    this.uuid_esercitazione = undefined;
    this.sortRisposte = undefined;
    this.tipo_esercitazione = undefined;
    this.n_quiz = undefined;
    this.tempo_tot = undefined;
    this.modalita_esercitazione = undefined;
    this.config_punteggio = undefined;
    this.dettaglioRisposteGettonate = [];
  }

  /**
   * Singleton getter
   * @returns {EsercitazioneManager}
   */
  static get instance() {
    if (!this.esercitazioneManagerInstance) {
      this.esercitazioneManagerInstance = new EsercitazioneManager();
    }
    return this.esercitazioneManagerInstance;
  }
}
